
	import _prop from 'vue-types';
	import {
		Swiper, Navigation, Pagination, Scrollbar, EffectFade,
	} from '@morev/vue-swiper';
	import { mergeObjects } from '@morev/utils';

	export default {
		name: 'common-swiper',
		inheritAttrs: false,
		components: { Swiper },
		props: {
			disabled: _prop.bool.def(false),
			lazyInit: _prop.bool.def(false),
			bemBlock: _prop.string.def(''),
			// Pagination
			pagination: _prop.oneOfType([Object, Boolean]).def(undefined),
			paginationOutside: _prop.bool.def(false),
			paginationWrap: _prop.bool.def(false),
			// Scrollbar
			scrollbar: _prop.oneOfType([Object, Boolean]).def(undefined),
			scrollbarOutside: _prop.bool.def(false),
			scrollbarWrap: _prop.bool.def(false),
			// Navigation
			navigation: _prop.oneOfType([Object, Boolean]).def(undefined),
			navigationWrap: _prop.bool.def(false),
			navigationOutside: _prop.bool.def(false),
		},

		/* eslint-disable vue/no-unused-emit-declarations -- The main component `$listeners` */
		emits: [
			'_beforeBreakpoint',
			'_containerClasses',
			'_slideClass',
			'_slideClasses',
			'_swiper',
			'activeIndexChange',
			'afterInit',
			'autoplay',
			'autoplayStart',
			'autoplayStop',
			'beforeDestroy',
			'beforeInit',
			'beforeLoopFix',
			'beforeResize',
			'beforeSlideChangeStart',
			'beforeTransitionStart',
			'breakpoint',
			'changeDirection',
			'click',
			'doubleTap',
			'doubleClick',
			'destroy',
			'fromEdge',
			'hashChange',
			'hashSet',
			'imagesReady',
			'init',
			'keyPress',
			'lazyImageLoad',
			'lazyImageReady',
			'loopFix',
			'momentumBounce',
			'navigationHide',
			'navigationShow',
			'observerUpdate',
			'orientationchange',
			'paginationHide',
			'paginationRender',
			'paginationShow',
			'paginationUpdate',
			'progress',
			'reachBeginning',
			'reachEnd',
			'realIndexChange',
			'resize',
			'scroll',
			'scrollbarDragEnd',
			'scrollbarDragMove',
			'scrollbarDragStart',
			'setTransition',
			'setTranslate',
			'slideChange',
			'slideChangeTransitionEnd',
			'slideChangeTransitionStart',
			'slideNextTransitionEnd',
			'slideNextTransitionStart',
			'slidePrevTransitionEnd',
			'slidePrevTransitionStart',
			'slideResetTransitionStart',
			'slideResetTransitionEnd',
			'sliderMove',
			'sliderFirstMove',
			'slidesLengthChange',
			'slidesGridLengthChange',
			'snapGridLengthChange',
			'snapIndexChange',
			'swiper',
			'tap',
			'toEdge',
			'touchEnd',
			'touchMove',
			'touchMoveOpposite',
			'touchStart',
			'transitionEnd',
			'transitionStart',
			'update',
			'zoomChange',
		],
		/* eslint-enable vue/no-unused-emit-declarations */
		data: () => ({}),
		computed: {
			cAttrs() {
				return mergeObjects(
					{
						spaceBetween: 20,
						speed: 400,
						roundLengths: true,
						// watchOverflow: true,
						grabCursor:	true,
						longSwipesRatio: .2,
						touchEventsTarget: 'container',
						noSwipingSelector: '.-no-swiping, input, textarea, select',
						emitStyle: 'kebab-case',
						modules: [
							Navigation,
							Pagination,
							Scrollbar,
							EffectFade,
						],
						pagination: {
							clickable: true,
						},
						scrollbar: {
							draggable: true,
						},
					},
					this.$props,
					this.$attrs,
				);
			},
		},
		methods: {},
	};
